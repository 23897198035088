import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useForgetPasswordCustomer } from '../../hooks'

import { SEO } from '../shared/SEO'
import { LinkType } from '../shared/LinkType'
import { BreadCrumbs } from '../shared'

import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  fonts,
  colors,
} from '../../styles'

export const ForgotPassword = ({ path, content }: { path: string }) => {
  const [
    forgetPassword,
    { response, loading, error },
  ] = useForgetPasswordCustomer()
  const [formSuccess, setFormSuccess] = useState(false)
  const [email, setEmail] = useState('')

  const form = React.createRef() as React.RefObject<HTMLFormElement>

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    forgetPassword(email)
  }

  useEffect(() => {
    if (response) {
      setFormSuccess(true)
    }
  }, [response])

  return (
    <div>
      <SEO title='forgot password' />
      <StyledBreadCrumbs
        breadcrumbs={[{ buttonText: 'home', target: { slug: '/' } }]}
        color='#000'
        title={content.title}
      />
      <form
        onSubmit={(e) => handleSubmit(e)}
        ref={form}
        aria-label='Forgot password form'
      >
        <Title>{content.title}</Title>
        <Description>{content.description}</Description>
        {error && (
          <div>
            <span role='img' aria-label='error'>
              ⚠️
            </span>
            : <Err> {error} </Err>
          </div>
        )}
        {formSuccess && <SuccessMessage>{content.successMessage}</SuccessMessage>}
        <Label htmlFor='forgot_email'>{content.emailLabel}</Label>
        <Input
          name='email'
          type='text'
          id='forgot_email'
          required={true}
          placeholder={content.emailPlaceholder}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Submit type='submit'>
          {loading ? <span>{content.loading}</span> : <span>{content.submit}</span>}
        </Submit>
        <AccountText>
          <StyledLinkType to='/account/login'>{content.cancel}</StyledLinkType>
        </AccountText>
      </form>
    </div>
  )
}

const StyledBreadCrumbs = styled(BreadCrumbs)`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    margin: 0 0 ${desktopVw(20)};
  }
`
const Err =styled.span`
  color: ${colors.red};
  font-weight: 700;
  font-size: ${mobileVw(14)};

  @media (min-width: ${desktopBreakpoint}){
    font-size: ${desktopVw(18)};
  }
`
const SuccessMessage =styled.span`
  font-size: ${mobileVw(14)};

  @media (min-width: ${desktopBreakpoint}){
    font-size: ${desktopVw(18)};
  }
`
const Title = styled.h5`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  line-height: ${mobileVw(23)};
  margin: 0 0 ${mobileVw(11)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(54)};
    line-height: 1;
    margin: 0 0 ${desktopVw(20)};
  }
`

const Description = styled.p`
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(18)};
  margin: 0 0 ${mobileVw(22)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(20)};
    line-height: ${desktopVw(24)};
    margin: 0 0 ${desktopVw(33)};
  }
`

const Label = styled.label`
  display: block;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: ${mobileVw(14)};
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  margin: 0 0 ${mobileVw(13)};
  text-transform: uppercase;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(13)};
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
  }
`

const Input = styled.input`
  display: block;
  border: 1px solid #000;
  background: transparent;
  padding: ${mobileVw(11.5)} ${mobileVw(16)} ${mobileVw(5.5)};
  font-family: 'MrsEaves';
  font-size: ${mobileVw(14)};
  line-height: ${mobileVw(24)};
  margin: 0 0 ${mobileVw(16)};
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(24)};
    padding: ${desktopVw(17)} ${desktopVw(16)} ${desktopVw(16)};
    width: ${desktopVw(540)};
  }
`

const Submit = styled.button`
  display: block;
  width: 100%;
  background-color: ${colors.mailleGold};
  color: #fff;
  padding: ${mobileVw(15.35)} 0 ${mobileVw(13.65)};
  font-size: ${mobileVw(20)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(2)};
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 ${mobileVw(26)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(22)} 0 ${desktopVw(16)};
    margin: 0 auto ${desktopVw(28)};
    width: ${desktopVw(350)};
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: 1;
    cursor: pointer;
  }
`

const AccountText = styled.p`
  position: relative;
  z-index: 1;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: ${mobileVw(16)};
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
  }
`

const StyledLinkType = styled(LinkType)`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    background-color: #000;
    width: 50%;
    height: 1px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: ${desktopBreakpoint}) {
    &::after {
      width: 100%;
      bottom: ${desktopVw(-2)};
    }
  }
`
