import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import { useLoginCustomer } from '../../hooks'

import { BreadCrumbs } from '../shared'

import { SEO } from '../shared/SEO'
import { LinkType } from '../shared/LinkType'
import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  fonts,
  colors,
} from '../../styles'

export const Login = ({ path, content }: { path: string }) => {
  const [loginCustomer, { response, loading, error }] = useLoginCustomer()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await loginCustomer(email, password)
  }
  useEffect(() => {
    if (response) {
      navigate('/account')
    }
  }, [response])

  return (
    <div>
      {/* <SEO title='login' /> */}
      <StyledBreadCrumbs
        breadcrumbs={[{ buttonText: 'home', target: { slug: '/' } }]}
        color='#000'
        title={content.slug}
      />
      <form onSubmit={(e) => handleSubmit(e)} aria-label='Login form'>
        <Title>{content.title}</Title>
        {error && (
          <Error>
            {typeof error === 'object' ? "le mot de passe ne correspond pas." : <Err> {error}</Err>}
          </Error>
        )}
        <Wrapper>
          <InputWrapper>
            <Label htmlFor='email'>{content.emailLabel}</Label>
            <Input
              id='email'
              name='email'
              type='text'
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder={content.emailPlaceholder}
            />
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor='password'>{content.passwordLabel}</Label>
            <Input
              id='password'
              name='password'
              type='password'
              required
              onChange={(e) => setPassword(e.target.value)}
              placeholder={content.passwordPlaceholder}
            />
          </InputWrapper>
        </Wrapper>
        <LoginButton type='submit'>
          {loading ? <span>{content.loadingText}</span> : <span>{content.login}</span>}
        </LoginButton>
        <div>
          <AccountText>
            <StyledLinkType to='/account/forgot'>
              {content.forgotPassword}
            </StyledLinkType>
          </AccountText>
          <AccountText>
            {content.dontHaveAnAccount}{' '}
            <StyledLinkType to='/account/register'>
              {content.dontHaveAnAccountLink}
            </StyledLinkType>
          </AccountText>
        </div>
      </form>
    </div>
  )
}

const StyledBreadCrumbs = styled(BreadCrumbs)`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    margin: 0 0 ${desktopVw(20)};
  }
`
const Err =styled.span`
  color: ${colors.red};
  font-weight: 700;
  font-size: ${mobileVw(14)};

  @media (min-width: ${desktopBreakpoint}){
    font-size: ${desktopVw(18)};
  }
`
const Title = styled.h5`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  line-height: ${mobileVw(23)};
  margin: 0 0 ${mobileVw(22)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(54)};
    line-height: 1;
    margin: 0 0 ${desktopVw(30)};
  }
`

const Wrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 ${desktopVw(47)};
  }
`

const InputWrapper = styled.div`
  margin: 0 0 ${mobileVw(13)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0;
  }
`

const Label = styled.label`
  display: block;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: ${mobileVw(14)};
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  margin: 0 0 ${mobileVw(13)};
  text-transform: uppercase;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(12)};
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
  }
`

const Input = styled.input`
  display: block;
  border: 1px solid #000;
  background: transparent;
  padding: ${mobileVw(11.5)} ${mobileVw(16)} ${mobileVw(5.5)};
  font-family: 'MrsEaves';
  font-size: ${mobileVw(14)};
  line-height: ${mobileVw(24)};
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(24)};
    padding: ${desktopVw(17)} ${desktopVw(16)} ${desktopVw(16)};
    width: ${desktopVw(540)};
  }
`

const LoginButton = styled.button`
  display: block;
  width: 100%;
  background-color: ${colors.mailleGold};
  color: #fff;
  padding: ${mobileVw(15.35)} 0 ${mobileVw(13.65)};
  font-size: ${mobileVw(20)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(2)};
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 ${mobileVw(24)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(22)} 0 ${desktopVw(16)};
    margin: 0 auto ${desktopVw(26)};
    width: ${desktopVw(350)};
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: 1;
    cursor: pointer;
  }
`

const AccountText = styled.p`
  position: relative;
  z-index: 1;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: ${mobileVw(16)};
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
  }
`

const StyledLinkType = styled(LinkType)`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    background-color: #000;
    width: 80%;
    height: 1px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: ${desktopBreakpoint}) {
    &::after {
      width: 100%;
      bottom: ${desktopVw(-2)};
    }
  }
`
const Error = styled.div`
  padding: ${mobileVw(10)};
  font-size: ${mobileVw(14)};
  @media(min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(10)} 0;
    font-size: ${desktopVw(14)};
  }
`
