import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import { useActivateCustomer } from '../../hooks'
import { SEO } from '../shared/SEO'
import { mobileVw, desktopBreakpoint, desktopVw, fonts, colors } from '../../styles'
const Activate = (props: { id: string; token: string }) => {
  const [activateCustomer, { response, loading, error }] = useActivateCustomer()
  const [sucsesMessage, setSucsesMessage] = useState(null)
  const [passwordField1, setPasswordField1] = useState('')
  const [passwordField2, setPasswordField2] = useState('')

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await activateCustomer(
      passwordField1,
      passwordField2,
      props.id,
      props.token,
    )
  }

  useEffect(() => {
    if (response) {
      setSucsesMessage('Activation réussie ! Vous pouvez vous connecter maintenant')
      setTimeout(() => {
        navigate('/account')
      }, 1000)
    }
  }, [response])

  return (
    <div>
      {/* <SEO title='activate account' /> */}
      <form
        onSubmit={(e) => handleSubmit(e)}
        aria-label='Activate account form'
      >
        <Title>Créer un compte</Title>
        {/* <SubTitle>Almost there.</SubTitle> */}
        {sucsesMessage && (
          <div>
            <span>{sucsesMessage}</span>
          </div>
        )}
        {error && (
          <div>
            <span>{error}</span>
          </div>
        )}
        {loading && (
          <div>
            <span>Chargement</span>
          </div>
        )}
        <Wrapper>
          <InputWrapper>
          <Label htmlFor='password'>MOT DE PASSE</Label>
          <Input
          type='password'
          value={passwordField1}
          onChange={(e) => setPasswordField1(e.target.value)}
          placeholder='MOT DE PASSE'
        />
          </InputWrapper>
          <InputWrapper>
        <Label htmlFor='password'>CONFIRMEZ VOTRE MOT DE PASSE</Label>
        <Input
          type='password'
          value={passwordField2}
          onChange={(e) => setPasswordField2(e.target.value)}
          placeholder='CONFIRMEZ VOTRE MOT DE PASSE'
        />
          </InputWrapper>
        </Wrapper>
        <ActivateButton type='submit'>
          {loading ? <span>Chargement</span> : <span>Activer</span>}
        </ActivateButton>
      </form>

      <AccountText>
      VOUS AVEZ DÉJÀ UN COMPTE ?  <Link to='/account/login'>CONNEXION</Link>
      </AccountText>
    </div>
  )
}

const Title = styled.h2`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  line-height: ${mobileVw(23)};
  margin: 0 0 ${mobileVw(22)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(54)};
    line-height: 1;
    margin: 0 0 ${desktopVw(30)};
  }
`
const SubTitle = styled.h3`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  line-height: ${mobileVw(23)};
  margin: 0 0 ${mobileVw(22)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(40)};
    line-height: 1;
    margin: 0 0 ${desktopVw(30)};
  }
`
const Wrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 ${desktopVw(47)};
  }
`
const InputWrapper = styled.div`
  margin: 0 0 ${mobileVw(13)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0;
  }
`
const Label = styled.label`
  display: block;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: ${mobileVw(14)};
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  margin: 0 0 ${mobileVw(13)};
  text-transform: uppercase;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(12)};
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
  }
`
const Input = styled.input`
  display: block;
  border: 1px solid #000;
  background: transparent;
  padding: ${mobileVw(11.5)} ${mobileVw(16)} ${mobileVw(5.5)};
  font-family: 'MrsEaves';
  font-size: ${mobileVw(14)};
  line-height: ${mobileVw(24)};
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(24)};
    padding: ${desktopVw(17)} ${desktopVw(16)} ${desktopVw(16)};
    width: ${desktopVw(540)};
  }
`
const ActivateButton = styled.button`
  display: block;
  width: 100%;
  background-color: ${colors.mailleGold};
  color: #fff;
  padding: ${mobileVw(15.35)} 0 ${mobileVw(13.65)};
  font-size: ${mobileVw(20)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(2)};
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 ${mobileVw(24)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(22)} 0 ${desktopVw(16)};
    margin: 0 auto ${desktopVw(26)};
    width: ${desktopVw(350)};
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: 1;
    cursor: pointer;
  }
`
const AccountText = styled.p`
  position: relative;
  z-index: 1;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: ${mobileVw(16)};
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
  }
`
export default Activate
