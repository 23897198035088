import React, { useEffect, useState } from 'react'
import { MailingAddress } from 'shopify-storefront-api-typings'
 import AddressForm from './addressForm'
import styled from 'styled-components'
import { useCustomer, useUpdateCustomer } from '../../hooks'
import { useCustomerAddress } from '../../hooks/user/useCustomerAddress'

import { Button } from '../shared'

import { SEO } from '../shared/SEO'
import { LinkType } from '../shared/LinkType'
import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  fonts,
  colors,
} from '../../styles'

interface AddressCardProps {
  address: MailingAddress
  isDefaultAddress?: boolean
}

const AddressCard = () => {
  const [toggleForm, setToggleForm] = useState(false)
  const [
    { updateDefaultAddress, deleteAddress },
    { response, loading, error },
  ] = useCustomerAddress()

  let user:any
  user = useCustomer()
  const [updateCustomer] = useUpdateCustomer()
  const { defaultAddress, addresses } = user
  let address = defaultAddress
  useEffect(() => {
    // console.log("toggle form", toggleForm);
    if (!toggleForm) {
      updateCustomer(user)
    //  console.log("updated User", user);
      
       address = user?.defaultAddress
    //   console.log("updated address", address);
       
    }
  }, [toggleForm])

console.log('test-address', user)
  return (
    <div style={{ width: '100%' }}>
      {!toggleForm ? (
        <>
          <Wrapper>
            <CategoryWrapper>
              <StyledTitle>Informations personnelles</StyledTitle>
              <StyledSpan>
                {address.firstName} {address.lastName}
              </StyledSpan>
              <StyledSpan>{user.email}</StyledSpan>
              <StyledSpan>**********</StyledSpan>
            </CategoryWrapper>
            <CategoryWrapper>
              <StyledTitle>Adresse</StyledTitle>
              {user.defaultAddress &&
                // user.addresses.edges.length >= 1 &&
                // user.addresses.edges.map(({ node }, i) => {
                //   return (
                //     <div key={i}>
                //       <StyledSpan>
                //         {node.address1 || '-'}, {node.address2}, {node.city}, {node.zip}, {' '}                      
                //         {node.country},
                //         {node.phone}
                //       </StyledSpan>
                //     </div>
                //   )
                // })
                    <div>
                        <StyledSpan>
                        {user.defaultAddress.address1 || '-'}, <br />
                        {user.defaultAddress.address2 || '-'}, <br />
                        {user.defaultAddress.city || '-'},
                        {user.defaultAddress.zip || '-'}, <br />
                        {user.defaultAddress.country || '-'}, <br />
                        {user.defaultAddress.phone || '-'}
                        </StyledSpan>
                   </div>
                }
              
              {/* <Button
                type='transparent'
                text={`Voir les adresses (${user.addresses.edges.length})`}
                onClick = {() => {setToggleForm(true)}}
                /> */}
            </CategoryWrapper>
            
            

            {/* <CategoryWrapper>
              <StyledTitle>Newsletter</StyledTitle>
              <StyledSpan>
                Recevez les dernières offres, nouveautés et recettes.
              </StyledSpan>
              <Button type='transparent' text='Gérer les abonnements' />
            </CategoryWrapper> */}
            {/* <CategoryWrapper>
              <StyledTitle>Préférences</StyledTitle>
              <StyledSpan>Je suis un foodie</StyledSpan>
              <StyledSpan>Je suis vegan</StyledSpan>
              <Button type='transparent' text='Modifier les préférences' />
            </CategoryWrapper> */}
             
          </Wrapper>
          <ButtonWrapper><Button type='transparent' text='Modifier' onClick = {() => {setToggleForm(true)}}/></ButtonWrapper>
          {error && <span>{error}</span>}
        </>
      ) : (
        <AddressForm
          formType='Modifier '
          setToggleForm={setToggleForm}
          currentAddress={address}
        />
      )}
    </div>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
`
const ButtonWrapper = styled.div`
  width: ${mobileVw(100)};
  margin-bottom: ${mobileVw(20)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(240)};
    margin-bottom: ${desktopVw(50)}
  }
`
const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: ${mobileVw(20)};
  align-items: flex-start;
  position: relative;
  min-height: ${mobileVw(60)};

  button {
    margin-top: ${mobileVw(19)};
    position: absolute;
    bottom: 0;
  }

  @media (min-width: ${desktopBreakpoint}) {
    min-height: ${desktopVw(170)};
    height: ${desktopVw(170)};
    width: ${desktopVw(230)};
    margin-right: ${desktopVw(200)};
    margin-bottom: ${desktopVw(35)};
    button {
      margin-top: ${desktopVw(19)};
    }
  }
`

const StyledTitle = styled.h2`
  font-family: ${fonts.tivoli};
  text-transform: uppercase;
  font-size: 1rem;
  margin-bottom: ${mobileVw(10)};

  @media (min-width: ${desktopBreakpoint}) {
     font-size: 1.5rem;
    line-height: ${desktopVw(24)};
    margin-bottom: ${desktopVw(10)};
    letter-spacing: ${desktopVw(2.2)};
  }
`

const StyledSpan = styled.span`
  margin-bottom: ${mobileVw(8)};
  font-size: 1rem;
  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: ${desktopVw(8)};
     font-size: 1.5rem;
  }
`

export default AddressCard
