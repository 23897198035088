import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { Check } from '../shared/icons'

import { useRegisterCustomer, useRegisterAnalytics } from '../../hooks'

import { BreadCrumbs } from '../shared'

import { SEO } from '../shared/SEO'
import { LinkType } from '../shared/LinkType'
import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  fonts,
  colors,
} from '../../styles'

export const Register = ({ path, content }: { path: string }) => {
  const [registerCustomer, { response, loading, error }] = useRegisterCustomer()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [passwordField1, setPasswordField1] = useState('')
  const [passwordField2, setPasswordField2] = useState('')

  const form = React.createRef() as React.RefObject<HTMLFormElement>

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    await registerCustomer(
      email,
      passwordField1,
      passwordField2,
      firstName,
      lastName,
      phoneNumber,
    )
  }

  useEffect(() => {
    if (response) {
      try {
        useRegisterAnalytics()
      } catch (err) {
        console.log(err);
      }
      navigate('/fr')
    }
  }, [response])

  return (
    <div>
      {/* <SEO title='create account' /> */}
      <StyledBreadCrumbs
        breadcrumbs={[{ buttonText: 'home', target: { slug: '/fr' } }]}
        color='#000'
        title={content.title}
      />
      <form
        onSubmit={(e) => handleSubmit(e)}
        ref={form}
        aria-label='Register form'
      >
        <Title>{content.title}</Title>
        {loading && <span>Chargement</span>}
        {error && (
          <Error>
            {typeof error === 'object' ? "Une erreur est survenue" : <Err> {error} </Err>}
          </Error>
        )}
        <Wrapper>
          <InputWrapper>
            <Label htmlFor='register_firstName'>{content.nameLabel}</Label>
            <Input
              name='firstName'
              type='text'
              id='register_firstName'
              required
              onChange={(e) => setFirstName(e.target.value)}
              placeholder={content.nameLabel}
            />
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor='register_lastName'>{content.lastNameLabel}</Label>
            <Input
              name='lastName'
              type='text'
              id='register_lastName'
              required
              onChange={(e) => setLastName(e.target.value)}
              placeholder={content.lastNameLabel}
            />
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor='register_email'>{content.emailLabel}</Label>
            <Input
              name='email'
              type='text'
              id='register_email'
              required
              onChange={(e) => setEmail(e.target.value)}
              placeholder={content.emailPlaceholder}
            />
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor='register_phone'>{content.telephoneLabel}</Label>
            <Input
              name='phone'
              type='tel'
              id='register_phone'
              required
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder={content.telephonePlaceholder}
            />
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor='register_password'>{content.passwordLabel}</Label>
            <Input
              name='password'
              id='register_password'
              value={passwordField1}
              onChange={(e) => setPasswordField1(e.target.value)}
              type='password'
              required
              placeholder={content.passwordPlaceholder}
            />
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor='register_passwordConfirmn'>
              {content.confirmPasswordLabel}
            </Label>
            <Input
              name='passwordConfirm'
              id='register_passwordConfirmn'
              value={passwordField2}
              onChange={(e) => setPasswordField2(e.target.value)}
              type='password'
              required
              placeholder={content.confirmPasswordPlaceholder}
            />
          </InputWrapper>
        </Wrapper>
        <List>
          {content.passwordRequirements.map((item, i) => {
            return (
              <ListItem key={i}>
                <StyledCheck />
                {item}
              </ListItem>
            )
          })}
        </List>
        <RegisterButton type='submit'>
          {loading ? <span>Chargement</span> : <span>{content.submit}</span>}
        </RegisterButton>
        <AccountText>
          {content.logIn}{' '}
          <StyledLinkType to='/account/login'>
            {content.logInLink}
          </StyledLinkType>
        </AccountText>
      </form>
    </div>
  )
}

const StyledBreadCrumbs = styled(BreadCrumbs)`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    margin: 0 0 ${desktopVw(20)};
  }
`

const Title = styled.h5`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  line-height: ${mobileVw(23)};
  margin: 0 0 ${mobileVw(22)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(54)};
    line-height: 1;
    margin: 0 0 ${desktopVw(30)};
  }
`
const Err =styled.span`
  color: ${colors.red};
  font-weight: 700;
  font-size: ${mobileVw(14)};

  @media (min-width: ${desktopBreakpoint}){
    font-size: ${desktopVw(18)};
  }
`
const Wrapper = styled.div`
  display: block;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: ${mobileVw(14)};
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  margin: 0 0 ${mobileVw(13)};
  text-transform: uppercase;

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 ${desktopVw(25)};
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
  }
`

const InputWrapper = styled.div`
  margin: 0 0 ${mobileVw(13)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(35)};

    &:nth-child(5),
    &:last-child {
      margin: 0;
    }
  }
`

const Label = styled.label`
  display: block;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: ${mobileVw(14)};
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  margin: 0 0 ${mobileVw(13)};
  text-transform: uppercase;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(12)};
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
  }
`

const Input = styled.input`
  display: block;
  border: 1px solid #000;
  background: transparent;
  padding: ${mobileVw(11.5)} ${mobileVw(16)} ${mobileVw(5.5)};
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(14)};
  line-height: ${mobileVw(24)};
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(24)};
    padding: ${desktopVw(17)} ${desktopVw(16)} ${desktopVw(16)};
    width: ${desktopVw(540)};
  }
`

const List = styled.ul`
  padding: 0;
  margin: 0 0 ${mobileVw(29)};
  list-style: none;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(31)};
  }
`

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  margin: 0 0 ${mobileVw(8)};
  padding: 0;
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(20)};

  &:last-child {
    margin: 0;
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(24)};
    margin: 0 0 ${desktopVw(16)};
    align-items: center;

    &:last-child {
      margin: 0;
    }
  }
`

const StyledCheck = styled(Check)`
  width: ${mobileVw(16)};
  height: ${mobileVw(16)};
  margin: 0 ${mobileVw(8)} 0 0;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(16)};
    height: ${desktopVw(10.14)};
    margin: 0 ${desktopVw(8)} 0 0;
  }
`

const RegisterButton = styled.button`
  display: block;
  width: 100%;
  background-color: ${colors.mailleGold};
  color: #fff;
  padding: ${mobileVw(15.35)} 0 ${mobileVw(13.65)};
  font-size: ${mobileVw(20)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(2)};
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 ${mobileVw(20)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(22)} 0 ${desktopVw(16)};
    margin: 0 auto ${desktopVw(26)};
    width: ${desktopVw(350)};
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: 1;
    cursor: pointer;
  }
`

const AccountText = styled.p`
  position: relative;
  z-index: 1;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: ${mobileVw(16)};
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
  }
`

const StyledLinkType = styled(LinkType)`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    background-color: #000;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: ${desktopBreakpoint}) {
    &::after {
      bottom: ${desktopVw(-2)};
    }
  }
`

const Error = styled.div`
  padding: ${mobileVw(10)};
  font-size: ${mobileVw(14)};
  @media(min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(10)} 0;
    font-size: ${desktopVw(14)};
  }
`
