const { mapLocale } = require('./mapping')
module.exports = {
  getPathname: (node_locale, slug) =>
    `/${mapLocale[node_locale]}${slug && slug !== '/' ? `${slug}` : '/'}`,

  updateCustomerName: async (token, firstName, lastName) => {
    if (token) {

      try {
        const data = await fetch(`/.netlify/functions/update-customer`, {
          method: 'POST',
          body: JSON.stringify({
            customerAccessToken: token,
            firstName,
            lastName,
          }),
        })
        const res = await data.json()
        if (res.error) {
          return {
            response: null,
            loading: false,
            error: res.error,
          }
        } else {
          return {
            response: res,
            loading: false,
            error: null,
          }
        }
      } catch (error) {
        throw new Error({
          response: null,
          loading: false,
          error,
        })
      }
    } else {
      return {
        response: null,
        loading: false,
        error: 'The "input" is missing.',
      }
    }
  }
}
