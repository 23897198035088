import React from 'react'
import AddressCard from './addressCard'
import {useCustomer} from '../../hooks/user/useCustomer'
// import AddressForm from './addressForm'

interface CustomerAddressProps {
  user: any
}

const CustomerAddressPanel = () => {
  const user = useCustomer()
  const { defaultAddress, addresses } = user
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {defaultAddress && (
        <div>
          <AddressCard />
        </div>
      )}
      <div>{/* <AddressForm formType='Create' /> */}</div>
    </div>
  )
}

export default CustomerAddressPanel
