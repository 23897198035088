import React, { useEffect, useState } from 'react'
import { useCustomer, useUpdateCustomer } from '../../hooks'
//import { useUpdateCustomerName } from '../../hooks/user/useUpdateCustomerName'
import {updateCustomerName} from '../../utils/functions'
import { Formik, Form, Field } from 'formik'
import styled from 'styled-components'
import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  fonts,
  colors,
} from '../../styles'

const NameForm = ({ formType, currentName, setToggleForm, firstName, lastName, setFirstName, setLastName }) => {
  const user = useCustomer()
  const [newFirstName, setNewFirstName] = useState(firstName)
  const [newLastName, setNewLastName] = useState(lastName)
  const [formSuccess, setFormSucces] = useState(false)
  const [responseValue, setResponseValue] = useState({ response: null, loading: false, error: null })
  const getInitialValues = (user) => ({
    firstName: user.firstName || '',
    lastName: user.lastName || '',
  })


  const updateNameHandler = async () =>  {
    setResponseValue({ response: null, loading: true, error: null })
    let resp = await updateCustomerName(user?.token, newFirstName, newLastName)
    console.log(resp, "resp");
    if (resp.response) {
      setFormSucces(true)
      window.scrollTo(0, 300)
       setFirstName(resp.response.res.customerUpdate.customer.firstName)
       setLastName(resp.response.res.customerUpdate.customer.lastName)
      setTimeout(() => setToggleForm(false), 2000)
    }
   // setTimeout(() => window.location.reload(), 2800)
    setResponseValue({ response: resp.response, loading: resp.loading, error: resp.error })
  }
  
  return (
    <Formik
      initialValues={getInitialValues(currentName || {})}
      onSubmit = {updateNameHandler}
      // onSubmit={() => {
      //   return (
      //     <>
      //       {formType === 'Create'
      //         ? updateCustomerName(user?.token, newFirstName, newLastName).then(
      //             () => console.log('success'),
      //           )
      //         : updateCustomerName(user?.token, newFirstName, newLastName).then (() => {
      //           setTimeout(() => {
      //             window.location.reload()
      //           }, 3000)
      //         })}
      //     </>
      //   )
      // }}
    >
      <Form>
        <b>{formType} nom</b>
        {formSuccess && (
          <Responce>Vos modifications ont bien été prises en compte</Responce>
        )}
        <NameContainer>
          <div>
            <InputWrapper>
              <Label htmlFor='user_firstName'>PRENOM*: </Label>
              <Field
                required
                name='firstName'
                id='user_firstName'
                value={newFirstName}
                onChange={(e) => setNewFirstName(e.target.value)}
              />
            </InputWrapper>
          </div>
          <div>
            <InputWrapper>
              <Label htmlFor='user_lastName'>NOM*: </Label>

              <Field
                required
                name='lastName'
                id='user_lastName'
                value={newLastName}
                onChange={(e) => setNewLastName(e.target.value)}
              />
            </InputWrapper>
          </div>
        </NameContainer>
        <ButtonWrapper>
          {setToggleForm && (
            <CancelButton onClick={() => setToggleForm(false)}>
              {' '}
              ANNULER
            </CancelButton>
          )}
          <LoadButton type='submit'>
            {responseValue.loading ? '...CHARGEMENT' : 'VALIDER'}
          </LoadButton>
        </ButtonWrapper>

        {responseValue.error && <span>{responseValue.error}</span>}
      </Form>
    </Formik>
  )
}

export default NameForm

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
  }
`
const InputWrapper = styled.div`
  margin: 0 0 ${mobileVw(13)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(28)};
  }
  & input {
    display: block;
    border: 1px solid #000;
    background: transparent;
    padding: ${mobileVw(11.5)} ${mobileVw(16)} ${mobileVw(5.5)};
    font-family: 'MrsEaves';
    font-size: ${mobileVw(14)};
    line-height: ${mobileVw(24)};
    width: 84vw;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: ${desktopVw(18)};
      line-height: ${desktopVw(24)};
      padding: ${desktopVw(17)} ${desktopVw(16)} ${desktopVw(16)};
      width: ${desktopVw(480)};
    }
  }
`
const Responce = styled.div`
  font-size: 1rem;
  margin: ${mobileVw(13)} 0 ${mobileVw(10)};
  @media (min-width: ${desktopBreakpoint}) {
     font-size: 1.5rem;
    margin: ${desktopVw(12)} 0 ${desktopVw(12)};
  }
` 
const Label = styled.label`
  display: block;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  margin: ${mobileVw(13)} 0 ${mobileVw(10)};
  text-transform: uppercase;

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(12)} 0 ${desktopVw(12)};
     font-size: 1.5rem;
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
  }
`
const CancelButton = styled.button`
  display: block;
  width: 100%;
  background-color: ${colors.mailleGold};
  color: #fff;
  padding: ${mobileVw(15.35)} 0 ${mobileVw(13.65)};
  font-size: ${mobileVw(20)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(2)};
  line-height: 1;
  text-transform: uppercase;
  margin: 0 ${mobileVw(8)} ${mobileVw(24)};
  padding: 0.5rem;
  margin: 1rem;
  width: 5rem;
  height: 2rem;
  font-size: 1rem;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(22)} 0 ${desktopVw(16)};
    margin: ${desktopVw(26)};
    width: ${desktopVw(150)};
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: 1;
    cursor: pointer;
    padding: 0.5rem;
    margin: 1rem;
    width: 7rem;
    height: 2.5rem;
    font-size: 1rem;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 2.5rem
`

const LoadButton = styled.button`
  display: block;
  width: 100%;
  background-color: ${colors.mailleGold};
  color: #fff;
  padding: ${mobileVw(15.35)} 0 ${mobileVw(13.65)};
  font-size: ${mobileVw(20)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(2)};
  line-height: 1;
  text-transform: uppercase;
  margin: 0 ${mobileVw(8)} ${mobileVw(24)};
  padding: 0.5rem;
  margin: 1rem;
  width: 5rem;
  height: 2rem;
  font-size: 1rem;

  @media (min-width: ${desktopBreakpoint}) {
    letter-spacing: ${desktopVw(1.8)};
    line-height: 1;
    cursor: pointer;
    padding: 0.5rem;
    margin: 1rem;
    width: 7rem;
    height: 2.5rem;
    font-size: 1rem;
  }
`
