import React from 'react'
import NameCard from './nameCard'
const CustomerNamePanel = () => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div>
        <NameCard />
      </div>
    </div>
  )
}

export default CustomerNamePanel