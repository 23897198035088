import React from 'react'
import { Router } from '@reach/router'
import styled from 'styled-components'

import { useSiteQuery } from '../hooks'
import { SEO } from '../components/shared'
import AuthWrapper from '../components/account/authWrapper'
// @ts-ignore
import Activate from '../components/account/activate'

import { ForgotPassword } from '../components/account/forgotPassword'
import { Register } from '../components/account/register'
import { Login } from '../components/account/login'
import { Reset } from '../components/account/reset'
import { InvalidToken } from '../components/account/invalid_token'
import { Portal } from '../components/account/portal'

import { mobileVw, desktopBreakpoint, desktopVw } from '../styles'
import { Image } from '../components/shared/Image'

const Account = () => {
  const { accountPage, accountDecoration } = useSiteQuery()

  return (
    <>
      <Wrapper>
        <SEO title='account pages' />
        <Router>
          <PublicRoute path='/account'>
            <AuthWrapper path='/' component={Portal} />
            <Login path='/login' content={accountPage[0]} />
            <Reset path='/reset/:id/:token' />
            <Activate
              // @ts-ignore
              path='/activate/:id/:token'
            />
            <InvalidToken path='/invalid_token' />
            <Register path='/register' content={accountPage[1]} />
            <ForgotPassword path='/forgot' content={accountPage[2]} />
          </PublicRoute>
        </Router>
      </Wrapper>
      <ImageWrapper>
        <StyledImage fluid={accountDecoration[0].image.fluid} />
        <StyledDesktopImage fluid={accountDecoration[1].image.fluid} />
      </ImageWrapper>
    </>
  )
}

function PublicRoute(props: { children: React.ReactNode; path: string }) {
  return <div>{props.children}</div>
}

const Wrapper = styled.div`
  margin: ${mobileVw(80)} 0 0;
  padding: ${mobileVw(33)} ${mobileVw(16)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(44)} 0 0;
    padding: 0 ${desktopVw(126)};
  }
`

const ImageWrapper = styled.div`
  margin: ${mobileVw(-25)} 0 0;

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    justify-content: flex-end;
    margin: ${desktopVw(17)} 0 0;
  }
`

const StyledImage = styled(Image)`
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const StyledDesktopImage = styled(Image)`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    width: ${desktopVw(570)};
  }
`

export default Account
