import React, { useEffect, useState, useContext } from 'react'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { UserNameContext } from '../../contexts'
import styled from 'styled-components'
import { useCustomer, useLogoutCustomer, useUpdateCustomer } from '../../hooks'
import CustomerAddressPanel from './customerAddressPanel'
import { BreadCrumbs, Productcard } from '../shared'
import { SEO } from '../shared/SEO'
import Wishlist from '../layout/Wishlist'
import CustomerNamePanel from './customerNamePanel'
import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  fonts,
  colors,
} from '../../styles'

export const Portal = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProduct {
        nodes {
          name
          thumbnailImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          title
          slug
          shopifyProduct {
            id
            handle
            variants {
              edges {
                node {
                  id
                  title
                  price{
                    amount
                  }
                }
              }
            }
          }
          isGourmetAndClassical
        }
      }
    }
  `)
  const allProducts = []
  data.allContentfulProduct.nodes.map((obj) => {
    const testcheck = obj.name.split(' ')
    if (obj.title && obj.slug && testcheck[0].toLowerCase() !== 'test') {
      allProducts.push(obj)
    }
  })
  const user = useCustomer()
  const [logoutCustomer, { response, error }] = useLogoutCustomer()
  const [updateCustomer] = useUpdateCustomer()

  const [openTab, setOpenTab] = useState('history')
  let {firstName, lastName, setFirstName, setLastName} = useContext(UserNameContext)
  const dateHandler = (string) => {
    const newDate = string.substring(0, 10).split('-')
    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`
  }

  const handleLogout = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    await logoutCustomer(user)
  }

  useEffect(() => {
    if (user) {
      updateCustomer(user)
    }
  }, [])

  useEffect(() => {
    if (response) {
      setTimeout(() => {
        navigate('/fr')
      }, 1000)
    }
  }, [response])

  return (
    <Wrapper>
      <StyledBreadCrumbs
        breadcrumbs={[{ buttonText: 'home', target: { slug: '/' } }]}
        color='#000'
        title='Mon compte'
      />
      {user && (
        <div>
          <Title>Bonjour {firstName}</Title>
          {/* <span>
            Consultez votre boîte mail pour savoir comment réinitialiser votre
            mot de passe.
          </span> */}
          <ul>
            <li>
              <StyledSpan
                isSelected={openTab === 'history'}
                onClick={() => setOpenTab('history')}
              >
                Historique d'achat
              </StyledSpan>
            </li>
            <li>
              <StyledSpan
                isSelected={openTab === 'favourites'}
                onClick={() => setOpenTab('favourites')}
              >
                Mes favoris
              </StyledSpan>
            </li>
          </ul>
          <OrderHistory display={openTab === 'history'}>
            {user.orders && user.orders.edges.length >= 1 ? (
              user.orders.edges?.map(({ node }, i) => {
                return (
                  <Orders key={i}>
                    <p>Numéro de commande: {node.orderNumber}</p>
                    <p>Commandé le: {dateHandler(node.processedAt)}</p>
                    <p>Prix: {node.totalPrice.amount}€</p>
                    <a href={node.statusUrl}>Statut de commande</a>
                    <StyledSpan>articles:</StyledSpan>
                    <div>
                      {node.lineItems.edges?.filter(({node}) => node && node.variant && node.variant.id).map(({ node }, i) => {
                        return (
                          <div key={i}>
                            <StyledListItem>
                              {node.quantity} x {node.title}{' '}
                              {node && node.variant && node.variant.price.amount}€
                            </StyledListItem>
                            <FlexBox>
                              {allProducts.filter(prod => prod?.shopifyProduct &&
                                  prod?.shopifyProduct?.variants?.edges[0]?.node
                                    ?.id ).map((product) =>
                                product.shopifyProduct &&
                                  product.shopifyProduct.variants.edges[0].node
                                    .id === node.variant.id ? (
                                  <div>
                                    <ProductCardWrapper>
                                      <Productcard
                                        recipePage
                                        product={product}
                                      />
                                    </ProductCardWrapper>
                                  </div>
                                ) : null,
                              )}
                            </FlexBox>
                          </div>
                        )
                      })}
                    </div>
                    <br />
                  </Orders>
                )
              })
            ) : (
              <StyledListItem>pas encore de commandes</StyledListItem>
            )}
          </OrderHistory>
          <WishlistWrapper display={openTab === 'favourites'}>
            <Wishlist portalView />
          </WishlistWrapper>
          <br />
          <Subtitle>Détails du compte</Subtitle>
          <CustomerNamePanel />
          <CustomerAddressPanel  />
          <LogoutButton onClick={(e) => handleLogout(e)}>
            {error ? 'Problème de connexion' : 'Déconnexion'}
          </LogoutButton>
        </div>
      )}
    </Wrapper>
  )
}

const StyledBreadCrumbs = styled(BreadCrumbs)`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    margin: 0 0 ${desktopVw(20)};
  }
`
const Wrapper = styled.div`
  min-height: 70vh;
  p + a {
    border-bottom: solid ${mobileVw(1)} ${colors.mailleGold};
  }

  p {
    margin: ${mobileVw(2)} 0;
  }

  ul {
    padding-inline-start: 0;
    li {
      display: inline-block;
      margin-right: ${mobileVw(20)};
      cursor: pointer;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    p + a {
      border-bottom: solid ${desktopVw(1)} ${colors.mailleGold};
    }
  }
`

const Orders = styled.div`
  border-top: solid ${mobileVw(1)} ${colors.mediumGrey};
  padding-top: ${mobileVw(16)};
  :first-child {
    border-top: none;
    padding-top: 0;
  }
  p {
    font-size: 1rem;
  }
  a{
    font-size: 1rem
  }
  @media (min-width: ${desktopBreakpoint}) {
    border-top: solid ${desktopVw(1)} ${colors.mediumGrey};
    padding-top: ${desktopVw(16)};
    :first-child {
      border-top: none;
      padding-top: 0;
    }
    p {
        font-size: 1.5rem;
      }
      a{
        font-size: 1.5rem
      }
  }
`

const OrderHistory = styled.ul`
  display: ${(props) => (props.display ? 'block' : 'none')};
`

const WishlistWrapper = styled.div`
  display: ${(props) => (props.display ? 'block' : 'none')};
  @media (min-width: ${desktopBreakpoint}) {
    .wishlist-products,
    .wishlist-recipes {
      width: ${desktopVw(350)};
    }
  }
`

const Title = styled.h5`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  line-height: ${mobileVw(23)};
  margin: 0 0 ${mobileVw(22)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(54)};
    line-height: 1;
    margin: 0 0 ${desktopVw(30)};
  }
`

const Subtitle = styled(Title)`
  font-size: ${mobileVw(25)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(54)};
  }
`

const StyledSpan = styled.span`
  display: block;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  margin: ${mobileVw(20)} 0;
  text-transform: uppercase;
  border-bottom: ${(props) =>
    props.isSelected ? `solid ${colors.black} ${mobileVw(1)}` : null};

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(24)} 0;
   // font-size: ${desktopVw(327)};
    font-size: 1.5rem;
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
    border-bottom: ${(props) =>
    props.isSelected ? `solid ${colors.black} ${desktopVw(1)}` : null};
  }
`

const StyledListItem = styled.li`
  display: block;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  margin: 0 0 ${mobileVw(13)};
  text-transform: uppercase;

  ul {
    padding-left: 0;
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(12)};
    font-size: 1.5rem;
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
    display: inline-block;
  }
`

const LogoutButton = styled.button`
  display: block;
  width: 100%;
  background-color: ${colors.mailleGold};
  color: #fff;
  padding: ${mobileVw(15.35)} 0 ${mobileVw(13.65)};
  font-size: ${mobileVw(20)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(2)};
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 ${mobileVw(70)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(22)} 0 ${desktopVw(16)};
    margin: 0 auto ${desktopVw(26)};
    width: ${desktopVw(350)};
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: 1;
    cursor: pointer;
  }
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    padding: 0;
    > div {
      margin-right: ${desktopVw(100)};
    }
  }
`
const ProductCardWrapper = styled.div`
display: flex;
width: ${mobileVw(350)};
justify-content: space-between;
padding: 0;
margin-bottom: ${mobileVw(30)};
width: 100%;

p {
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(16)};
  height: auto;
}

.imageContainer {
  mix-blend-mode: multiply;
  width: 100%;

  img {
    object-fit: contain !important;
    padding: ${mobileVw(16)};
    max-width: 100%;
  }
}
.product-wrapper,
.image-link {
  height: ${mobileVw(176)};
}

.text-wrapper {
  margin-left: ${mobileVw(30)};
  max-width: ${mobileVw(150)};
  > div {
    min-height: 100%;
    > div {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.image-link {
  margin-bottom: 0;
  width: ${mobileVw(136)};
}

  @media (min-width: ${desktopBreakpoint}) {
  width: ${desktopVw(350)};
  margin-bottom: ${desktopVw(30)};

  p {
    font-size: ${desktopVw(22)};
    line-height: ${desktopVw(24)};
  }

  .imageContainer {
    img {
      padding: ${desktopVw(16)};
    }
  }
  .product-wrapper,
  .image-link {
    height: ${desktopVw(216)};
  }

  .text-wrapper {
    margin-left: ${desktopVw(30)};
    max-width: ${desktopVw(150)};
  }

  .image-link {
    width: ${desktopVw(160)};
  }
`
