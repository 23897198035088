import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import {
  MailingAddress,
  MailingAddressInput,
} from 'shopify-storefront-api-typings'
import styled from 'styled-components'
import { useCustomer, useCreateAddress, useUpdateCustomer } from '../../hooks'
import { useCustomerAddress } from '../../hooks/user/useCustomerAddress'
import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  fonts,
  colors,
} from '../../styles'
import { Button } from '../shared'
interface AddressFormProps {
  formType: string
  currentAddress?: MailingAddress
  setToggleForm?: React.Dispatch<React.SetStateAction<boolean>>
}

const AddressForm = ({
  formType,
  currentAddress,
  setToggleForm,
}: AddressFormProps) => {
  const getInitialValues = (address: MailingAddressInput) => ({
    address1: address.address1 || '',
    address2: address.address2 || '',
    city: address.city || '',
    company: address.company || '',
    country: address.country || '',
    firstName: address.firstName || '',
    lastName: address.lastName || '',
    phone: address.phone || '',
    province: address.province || '',
    zip: address.zip || '',
  })
  const user = useCustomer()
  const [formSuccess, setFormSucces] = useState(false)
  const [{ createAddress, updateAddress }, { response, loading, error }] =
    useCustomerAddress()
  useEffect(() => {
    if (response) {
      setFormSucces(true)
    }
  }, [response])
  return (
    <Formik
      initialValues={getInitialValues(currentAddress || {})}
      onSubmit={(values) => {
        console.log(currentAddress.id)
        return (
          <>
            {formType === 'Create'
              ? createAddress(user.token, values)
              : updateAddress(user.token, currentAddress.id, values).then(() => {
                window.scrollTo(0, 300)
                setTimeout(() => {
                  window.location.reload()
                }, 3000)
              })}
            {/* {setTimeout(() => {
              return (
                <>
                  {window.location.reload()}
                </>
              )
            }, 3000)} */}
          </>
        )
      }}
    >
      <Form>
        <b>{formType} adresse</b>
        {formSuccess && (
          <Responce>Vos modifications ont bien été prises en compte</Responce>
        )}
        <NameContainer style={{ listStyle: 'none', padding: 0 }}>
          <div>
            <InputWrapper>
              <Label htmlFor='address_firstName'>PRENOM*: </Label>
              <Field required name='firstName' id='address_firstName' />
            </InputWrapper>
          </div>
          <div>
            <InputWrapper>
              <Label htmlFor='address_lastName'>NOM*: </Label>

              <Field required name='lastName' id='address_lastName' />
            </InputWrapper>
          </div>
          <div>
            <InputWrapper>
              <Label htmlFor='address_company'>SOCIETE: </Label>

              <Field name='company' id='address_company' />
            </InputWrapper>
          </div>
          <div>
            <InputWrapper>
              <Label htmlFor='address_addressField1'>ADRESSE 1*: </Label>

              <Field required name='address1' id='address_addressField1' />
            </InputWrapper>
          </div>
          <div>
            <InputWrapper>
              <Label htmlFor='address_addressField2'>ADRESSE 2: </Label>

              <Field name='address2' id='address_addressField2' />
            </InputWrapper>
          </div>
          <div>
            <InputWrapper>
              <Label htmlFor='address_zipcode'>CODE POSTAL*: </Label>

              <Field required name='zip' id='address_zipcode' />
            </InputWrapper>
          </div>
          <div>
            <InputWrapper>
              <Label htmlFor='address_city'>VILLE*: </Label>

              <Field required name='city' id='address_city' />
            </InputWrapper>
          </div>
          <div>
            <InputWrapper>
              <Label htmlFor='address_country'>PAYS*: </Label>

              <Field required name='country' id='address_country' />
            </InputWrapper>
          </div>
          <div>
            <InputWrapper>
              <Label htmlFor='address_phone'>TELEPHONE: </Label>

              <Field name='phone' id='address_phone' />
            </InputWrapper>
          </div>
        </NameContainer>
        <ButtonWrapper>
          {setToggleForm && (
            <CancelButton onClick={() => setToggleForm(false)}>
              {' '}
              Annuler
            </CancelButton>
          )}
          <LoadButton type='submit'>
            {loading ? '...CHARGEMENT' : 'VALIDER'}
          </LoadButton>
        </ButtonWrapper>
        {error && <span>{error}</span>}
      </Form>
    </Formik>
  )
}

export default AddressForm

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
  }
`
const InputWrapper = styled.div`
  margin: 0 0 ${mobileVw(13)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(28)};
  }

  input {
    display: block;
    border: 1px solid #000;
    background: transparent;
    padding: ${mobileVw(11.5)} ${mobileVw(16)} ${mobileVw(5.5)};
    font-family: 'MrsEaves';
    font-size: ${mobileVw(14)};
    line-height: ${mobileVw(24)};
    width: 84vw;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: ${desktopVw(18)};
      line-height: ${desktopVw(24)};
      padding: ${desktopVw(17)} ${desktopVw(16)} ${desktopVw(16)};
      width: ${desktopVw(480)};
    }
  }
`
const Responce = styled.div`
  font-size: 1rem;
  margin: ${mobileVw(13)} 0 ${mobileVw(10)};
  @media (min-width: ${desktopBreakpoint}) {
     font-size: 1.5rem;
    margin: ${desktopVw(12)} 0 ${desktopVw(12)};
  }
`
const Label = styled.label`
  display: block;
  font-family: ${fonts.tivoli};
  font-weight: 200;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: ${mobileVw(1.4)};
  margin: ${mobileVw(13)} 0 ${mobileVw(10)};
  text-transform: uppercase;

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(12)} 0 ${desktopVw(12)};
     font-size: 1.5rem;
    line-height: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.8)};
  }
`

const Input = styled.span`
  display: block;
  border: 1px solid #000;
  background: transparent;
  padding: ${mobileVw(11.5)} ${mobileVw(16)} ${mobileVw(5.5)};
  font-family: 'MrsEaves';
  font-size: ${mobileVw(14)};
  line-height: ${mobileVw(24)};
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(24)};
    padding: ${desktopVw(17)} ${desktopVw(16)} ${desktopVw(16)};
    width: ${desktopVw(340)};
  }
`
const CancelButton = styled.button`
  display: block;
  width: 100%;
  background-color: ${colors.mailleGold};
  color: #fff;
  padding: ${mobileVw(15.35)} 0 ${mobileVw(13.65)};
  font-size: ${mobileVw(20)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(2)};
  line-height: 1;
  text-transform: uppercase;
  margin: 0 ${mobileVw(8)} ${mobileVw(24)};
  padding: 0.5rem;
  margin: 1rem;
  width: 5rem;
  height: 2rem;
  font-size: 1rem;
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(22)} 0 ${desktopVw(16)};
    margin: ${desktopVw(26)};
    width: ${desktopVw(150)};
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: 1;
    cursor: pointer;
    padding: 0.5rem;
    margin: 1rem;
    width: 7rem;
    height: 2.5rem;
    font-size: 1rem;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
`

const LoadButton = styled.button`
  display: block;
  width: 100%;
  background-color: ${colors.mailleGold};
  color: #fff;
  padding: ${mobileVw(15.35)} 0 ${mobileVw(13.65)};
  font-size: ${mobileVw(20)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(2)};
  line-height: 1;
  text-transform: uppercase;
  margin: 0 ${mobileVw(8)} ${mobileVw(24)};
  padding: 0.5rem;
  margin: 1rem;
  width: 5rem;
  height: 2rem;
  font-size: 1rem;
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(22)} 0 ${desktopVw(16)};
    margin: ${desktopVw(26)};
    width: ${desktopVw(150)};
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: 1;
    cursor: pointer;
    padding: 0.5rem;
    margin: 1rem;
    width: 7rem;
    height: 2.5rem;
    font-size: 1rem;
  }
`
