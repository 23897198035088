import React, {useState, useContext} from 'react'
import { LinkType } from '../shared/LinkType'
import { useCustomer, useUpdateCustomer } from '../../hooks'
import styled from 'styled-components'
import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  fonts,
  colors,
} from '../../styles'
import NameForm from './nameForm'
import { Button } from '../shared'
import { UserNameContext } from '../../contexts'
const NameCard = () => {
  let user:any
  user = useCustomer()
  const [updateCustomer] = useUpdateCustomer()
  const [toggleForm, setToggleForm] = useState(false)
  let {firstName, lastName, setFirstName, setLastName} = useContext(UserNameContext)
  // const [firstName, setFirstName] = useState(user.firstName)
  // const [ lastName, setLastName] = useState(user.lastName)
  return (
    <div style={{ width: '100%' }}>
    <>
      {!toggleForm ? 
          <Wrapper>
            <CategoryWrapper>
              <StyledTitle>NOM DU COMPTE </StyledTitle>
              <StyledSpan>
                {firstName} {lastName}
              </StyledSpan>
              <Button type='transparent' text='Modifier' onClick = {() => {setToggleForm(true)}}/>
            </CategoryWrapper>
          </Wrapper>
          : 
          <NameForm 
          formType='Editer'
          setToggleForm={setToggleForm}
          currentName={user.name}
          firstName = {firstName}
          lastName = {lastName}
          setFirstName = {setFirstName}
          setLastName = {setLastName}
          />
      }
      </>
    </div>
  )
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
`
const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: ${mobileVw(20)};
  align-items: flex-start;
  position: relative;
  min-height: ${mobileVw(120)};

  button {
    margin-top: ${mobileVw(19)};
    position: absolute;
    bottom: 0;
  }

  @media (min-width: ${desktopBreakpoint}) {
    min-height: ${desktopVw(125)};
    height: ${desktopVw(125)};
    // width: ${desktopVw(230)};
    margin-right: ${desktopVw(200)};
    margin-bottom: ${desktopVw(35)};
    button {
      margin-top: ${desktopVw(19)};
    }
  }
`

const StyledTitle = styled.h2`
  font-family: ${fonts.tivoli};
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: ${mobileVw(10)};

  @media (min-width: ${desktopBreakpoint}) {;
    line-height: ${desktopVw(24)};
    margin-bottom: ${desktopVw(10)};
    letter-spacing: ${desktopVw(2.2)};
  }
`
const StyledSpan = styled.span`
  margin-bottom: ${mobileVw(8)};
  font-size: 1rem;
  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: ${desktopVw(8)};
    font-size: 1.5rem;
  }
`

export default NameCard